{
  "name": "frontend",
  "version": "1.0.0",
  "schemaVersion": "1.0.0",
  "private": true,
  "scripts": {
    "start": "parcel public/index.html --no-cache",
    "prebuild": "rm -rf dist",
    "build": "parcel build public/index.html",
    "lint": "eslint . --ext .js,.ts,.tsx --cache --cache-location '../.eslintcache'",
    "lint:fix": "yarn lint --fix",
    "check:types": "tsc --noemit"
  },
  "dependencies": {
    "@automatarium/jflap-translator": "1.0.0",
    "@automatarium/simulation": "2.0.0",
    "@msgpack/msgpack": "^2.8.0",
    "axios": "^0.26.1",
    "dayjs": "^1.11.2",
    "goober": "^2.1.8",
    "immer": "^9.0.12",
    "lodash.clonedeep": "^4.5.0",
    "lodash.groupby": "^4.6.0",
    "lodash.isequal": "^4.5.0",
    "lucide-react": "^0.17.9",
    "prop-types": "^15.8.1",
    "react": "^17.0.2",
    "react-a11y-dialog": "^6.1.5",
    "react-dom": "^17.0.2",
    "react-hook-form": "^7.28.1",
    "react-lottie-player": "1.5.4",
    "react-router-dom": "^6.2.2",
    "zustand": "^4.3.7"
  },
  "devDependencies": {
    "@parcel/transformer-inline-string": "^2.8.3",
    "@types/lodash.groupby": "^4.6.7",
    "@types/lodash.isequal": "^4.5.6",
    "@types/node": "^20.2.5",
    "@types/react": "^18.0.33",
    "assert": "^2.0.0",
    "buffer": "^5.5.0||^6.0.0",
    "eslint": "^8.11.0",
    "eslint-plugin-react": "^7.29.4",
    "parcel": "^2.8.3",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "util": "^0.12.3"
  }
}

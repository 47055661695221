{
  "id": "tutorials",
  "title": "Tutorials",
  "blurb": "",
  "description": "Explore how to use Automatarium with the following tutorials.",
  "type": "section",
  "items": [
    {
      "id": "fsa-walkthrough",
      "title": "FSA Tutorial",
      "blurb": "In this tutorial, learn how to create a Finite State Automaton in Automatarium.",
      "description": "<p>GitHub: <a href='https://github.com/automatarium/automatarium'>https://github.com/automatarium/automatarium</a></p><p>Contact: <a href='mailto:automatarium.manager@gmail.com'>automatarium.manager@gmail.com</a></p><p>Voice: Russell from <a href='https://play.ht/'>https://play.ht/</a></p>",
      "type": "item",
      "link": "https://youtu.be/eYXXIuv-Joo"
    },
    {
      "id": "pda-walkthrough",
      "title": "PDA Tutorial",
      "blurb": "In this tutorial, learn how to create a Pushdown Automaton in Automatarium.",
      "description": "<p>GitHub: <a href='https://github.com/automatarium/automatarium'>https://github.com/automatarium/automatarium</a></p><p>Contact: <a href='mailto:automatarium.manager@gmail.com'>automatarium.manager@gmail.com</a></p><p>Voice: Russell from <a href='https://play.ht/'>https://play.ht/</a></p>",
      "type": "item",
      "link": "https://youtu.be/TMkRywWW_hs"
    },
    {
      "id": "tm-walkthrough",
      "title": "TM Tutorial",
      "blurb": "In this tutorial, learn how to create a Turing Machine in Automatarium.",
      "description": "<p>GitHub: <a href='https://github.com/automatarium/automatarium'>https://github.com/automatarium/automatarium</a></p><p>Contact: <a href='mailto:automatarium.manager@gmail.com'>automatarium.manager@gmail.com</a></p><p>Voice: Russell from <a href='https://play.ht/'>https://play.ht/</a></p>",
      "type": "item",
      "link": "https://youtu.be/kX0W6pmdTfo"
    },
    {
      "id": "creating-project-walkthrough",
      "title": "Getting Started",
      "blurb": "In this tutorial, learn how to create a project and customise your workspace in Automatarium.",
      "description": "<p>GitHub: <a href='https://github.com/automatarium/automatarium'>https://github.com/automatarium/automatarium</a></p><p>Contact: <a href='mailto:automatarium.manager@gmail.com'>automatarium.manager@gmail.com</a></p><p>Voice: Russell from <a href='https://play.ht/'>https://play.ht/</a></p>",
      "type": "item",
      "link": "https://youtu.be/qrVVrJoZlrk"
    },
    {
      "id": "transitions-walkthrough",
      "title": "Transitions Tutorial",
      "blurb": "In this tutorial, learn how to manage transitions using Automatarium's unique features.",
      "description": "<p>GitHub: <a href='https://github.com/automatarium/automatarium'>https://github.com/automatarium/automatarium</a></p><p>Contact: <a href='mailto:automatarium.manager@gmail.com'>automatarium.manager@gmail.com</a></p><p>Voice: Russell from <a href='https://play.ht/'>https://play.ht/</a></p>",
      "type": "item",
      "link": "https://youtu.be/RAoQh3z-Y0I"
    },
    {
      "id": "template-walkthrough",
      "title": "Templates Tutorial",
      "blurb": "In this tutorial, learn how to use templates in Automatarium.",
      "description": "<p>GitHub: <a href='https://github.com/automatarium/automatarium'>https://github.com/automatarium/automatarium</a></p><p>Contact: <a href='mailto:automatarium.manager@gmail.com'>automatarium.manager@gmail.com</a></p><p>Voice: Russell from <a href='https://play.ht/'>https://play.ht/</a></p>",
      "type": "item",
      "link": "https://youtu.be/m6wTSj7-Oe0"
    }
  ]
}